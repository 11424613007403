@font-face {
  font-family: "Pretendard";
  font-display: fallback;
  font-weight: 100;
  src: url("./style/fonts/Pretendard-Thin.otf") format("opentype");
}

@font-face {
  font-family: "Pretendard";
  font-display: fallback;
  font-weight: 300;
  src: url("./style/fonts/Pretendard-Light.otf") format("opentype");
}

@font-face {
  font-family: "Pretendard";
  font-display: fallback;
  font-weight: 400;
  src: url("./style/fonts/Pretendard-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Pretendard";
  font-display: fallback;
  font-weight: 600;
  src: url("./style/fonts/Pretendard-Bold.otf") format("opentype");
}

body {
  margin: 0;
  font-family: "Pretendard";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
